import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from '@elastic/eui'

export default function ImageWall() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="s" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign='left'
              paddingSize='l'
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/Advanced/ImageWall/InstarVision_Android_Widget_03.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Camera Widget'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        To add a camera preview widget to your phone open the widget menu - usually be touching and holding the desktop until the context menu opens.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign='left'
              paddingSize='m'
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/Advanced/ImageWall/InstarVision_Android_Widget_04.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Widgets Menu'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                          In the Widgets menu scroll down and open the InstarVision sub menu and drag&drop the menu onto your desktop.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Advanced/ImageWall/InstarVision_Android_Widget_05.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Select your Camera'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                            You will now be asked to select the camera you want to add.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Advanced/ImageWall/InstarVision_Android_Widget_06.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Widget Position'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          And adjust the widget position and size according to your needs.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
            </EuiFlexItem>
            <EuiFlexItem>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
    </React.Fragment>
  );
}