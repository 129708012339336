import React from 'react'

import {
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'

import ImageWall from '../../../../../components/Software/Android/InstarVision/ImageWalls/instarvision-android-widget'
import FloatingGallery from "../../../../../components/Software/Android/InstarVision/Galleries/instarvision-android-advanced-floating-gallery"

import NavButtons from '../../../../../components/Software/Android/InstarVision/NavButtons'

import SEOHelmet from '../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'Android App InstarVision',
  description: 'The Android app InstarVision for your Android tablet or smart phone. Control your camera when you don´t have access to your computer.',
  image: '/images/Search/P_SearchThumb_InstarVision_Android.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_Android.webp',
  locationEN: '/en/Software/Android/InstarVision/Advanced/',
  locationDE:'/de/Software/Android/InstarVision/Advanced/',
  locationFR:'/fr/Software/Android/InstarVision/Advanced/'
}

function InstarVisionAdvanced(props) {
  return (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
    locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="Advanced" />
          
      <NavButtons />

      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>Android Software</h2>
      </EuiTitle>
      <EuiTitle size="s">
        <h4>Advanced Camera Control</h4>
      </EuiTitle>
      <EuiSpacer />
      <FloatingGallery />
      <EuiSpacer size="l" />
      <ImageWall />
    </React.Fragment>
  );
}

export default InstarVisionAdvanced